import React from 'react';
import { Show, 
    SimpleShowLayout, 
    TextField,
    useRecordContext,
    TabbedShowLayout,
    Tab,
    FunctionField
} from 'react-admin';

const ImageRenderer = ({ record }) => {
  if(record.length < 1 || !record[0].id) return <img src="/images/no-photo.jpg"  height="72" alt="not found" />;
  return (
      <div>
          {record.map((item, index) => <a href={`https://drive.google.com/file/d/${item.id}/view`} target="_blank" rel="noreferrer">
              <img 
                src={`https://drive.google.com/thumbnail?id=${item.id}`} 
                alt="img from GG" 
                height="72" 
                loading="lazy" 
                style={{ marginRight: '10px'}} 
              /> 
            </a> )}
      </div>
  );
};

const ShowVideo = (props) => {
  const TabVideoInfo = () => {
    const record = useRecordContext();
    return (
      <SimpleShowLayout>
        <TextField source="name" />
        <FunctionField label="Google Drive Location" render={(record) =>{
            if (!record.directory) return <span>Pending</span>
            return <a rel="noreferrer" target="_blank" href={`https://drive.google.com/drive/folders/${record.directory}`}>{record.directory}</a>}
        }/> 
        <FunctionField label="Idea" render={(record) =>{
          if(record.type){
            if(!record.job || !record.job.jobInfo.idea) return <span>Pending</span>
          }
          return <ImageRenderer record={record.type? record.job.jobInfo.idea : record.extraInfo.ideas}/>
        }}
        />
        {record.type && <FunctionField label="Outline" render={(record) =>{
          if(!record.job.jobInfo || !record.job.jobInfo.outline) return <span>Pending</span>
          return <a href={`https://docs.google.com/document/d/${record.job.jobInfo.outline}/edit`} target="_blank" rel="noreferrer">View Outline</a>
        }}/>}

        {record.type && <FunctionField label="Script" render={(record) =>{
          if(!record.job.jobInfo || !record.job.jobInfo.script) return <span>Pending</span>
          return <a href={`https://docs.google.com/document/d/${record.job.jobInfo.script}/edit`} target="_blank" rel="noreferrer">View Outline</a>
        }}/>}
        <TextField source="publishedDate" />
        <TextField source="status" />
      </SimpleShowLayout>
    )
  }
  return(
    <Show {...props}>    
      <TabbedShowLayout>
        <Tab label="Video Info">
           <TabVideoInfo />
        </Tab>
        <Tab label="Video">
          <FunctionField render={(record) =>{
            if(record.type){if (!record.jobInfo || !record.jobInfo.video ) return <span>No Video to Show</span>}
            return (
              <iframe 
                width="1280" height="720" 
                title="View Video"  
                src={`https://drive.google.com/file/d/${record.type? record.job.jobInfo.video : record.extraInfo.videoId}/preview`}
                frameBorder="0" allowFullScreen>
              </iframe>
            )
          }}/> 
        </Tab>
        <Tab label="Thumbnail">
          <FunctionField render={(record) =>{
            if(record.type){if (!record.jobInfo || !record.jobInfo.thumbnails ) return <span>No Thumbnail to Show</span>}
            const thumbnails = record.type? record.job.jobInfo.thumbnails : record.extraInfo.thumbnails;
            return (<>
              {thumbnails.map((item, index) =>{
                return <img 
                    src={"https://drive.google.com/thumbnail?id="+item.id} 
                    alt="img from GG" height="150" 
                    style={{ border: `2px solid`, cursor: 'pointer' }}
                    loading="lazy"
                    onClick={()=>window.open(`https://drive.google.com/file/d/${item.id}/view`, "_blank")}
                />
              })}
            </>);
          }}/>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
};

export default ShowVideo;