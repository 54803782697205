import React, {useEffect, useState, useContext } from "react";
import { MultiLevelMenu } from "@react-admin/ra-navigation";
import { UserContext } from "./auth/UserContext";

//icons
import {
  TipsAndUpdates as TipsAndUpdatesIcon,
  ViewInArOutlined as ViewInArOutlinedIcon,
  VideoCameraBackOutlined as VideoCameraBackOutlinedIcon,
  TaskOutlined as TaskOutlinedIcon,
  Work as WorkIcon,
  EditNote as EditNoteIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  EmergencyRecording as EmergencyRecordingIcon,
  PlayCircle as PlayCircleIcon,
  MovieFilter as MovieFilterIcon,
  Theaters as TheatersIcon,
  CalendarMonth as CalendarMonthIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon,
  Diversity3 as Diversity3Icon,
  YouTube as YouTubeIcon,
  Schedule as ScheduleIcon
} from '@mui/icons-material';
export const MyMenu = (props) =>{
    const authUser = useContext(UserContext);
    const isAdmin = authUser?.role > 0;
    const [isManager, setIsManager] = useState(false);

    useEffect(() => {
        async function fetchData() {
          if(!authUser) return;
          authUser.teams.forEach(team => {
            if(team.role === 3){setIsManager(true); return}
          });
        }
        fetchData();
    }, [authUser]);

    return (
        <MultiLevelMenu initialOpen>
            <MultiLevelMenu.Item name = "task" to="task" label="Task" icon={<TaskOutlinedIcon/>}/>
            <MultiLevelMenu.Item name="workspace" label="WorkSpace" icon={<WorkIcon/>}>
                {authUser?.skills?.includes(1) && <MultiLevelMenu.Item name = "idea" to="workspace/idea" label="Idea" icon={<TipsAndUpdatesIcon/>}/>}
                {authUser?.skills?.includes(2) && <MultiLevelMenu.Item name = "outline" to="workspace/outline" label="Outline" icon={<DescriptionOutlinedIcon/>}/>}
                {authUser?.skills?.includes(4) && <MultiLevelMenu.Item name = "script" to="workspace/script" label="Script" icon={<EditNoteIcon/>}/>}
                {authUser?.skills?.includes(5) &&<MultiLevelMenu.Item name = "film" to="workspace/film" label="Film" icon={<EmergencyRecordingIcon/>}/>}
                {authUser?.skills?.includes(6) &&<MultiLevelMenu.Item name = "edit" to="workspace/edit" label="Edit" icon={<PlayCircleIcon/>}/>}
                {authUser?.skills?.includes(8) &&<MultiLevelMenu.Item name = "thumbnail" to="workspace/thumbnail" label="Thumbnail" icon={<MovieFilterIcon/>}/>}
            </MultiLevelMenu.Item>
            {(isAdmin || isManager)  && (<MultiLevelMenu.Item name = "job" to="job" label="Job" icon={<VideoCameraBackOutlinedIcon/>}/>)}
            {(isAdmin || isManager)  && (<MultiLevelMenu.Item name = "schedule" to="schedule" label="Schedule" icon={<ScheduleIcon/>}/>)}
            <MultiLevelMenu.Item name = "video" to="video" label="Videos" icon={<TheatersIcon/>}/>
            <MultiLevelMenu.Item name = "calendar" to="calendar" label="Calendar" icon={<CalendarMonthIcon/>}/>
            { isAdmin &&(<MultiLevelMenu.Item name = "user" to="user" label="User" icon={<PeopleAltOutlinedIcon/>}/>)}
            {(isAdmin || isManager) &&(<MultiLevelMenu.Item name = "team" to="team" label="Team" icon={<Diversity3Icon/>}/>)}
            {(isAdmin || isManager)  &&(<MultiLevelMenu.Item name = "channel" to="channel" label="Channel" icon={<YouTubeIcon/>}/>)}
            {(isAdmin || isManager)  &&(<MultiLevelMenu.Item name = "jobtype" to="jobType" label="JobType" icon={<ViewInArOutlinedIcon/>}/>)}            
        </MultiLevelMenu>)
}