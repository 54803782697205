import  { useState, useEffect, React  } from "react";
import { 
  Admin,
  Resource
} from 'react-admin';

import {UserContext} from './components/auth/UserContext';
import { HashRouter as Router, useNavigate } from 'react-router-dom';
import authProvider from "./components/auth/authProvider";
import restProvider from './data-provider/rest-provider';
import NotFound from './components/NotFound';
import  ListCalendar from './components/Calendars/ListCalendar';
import ListJob from "./components/Job/ListJob";
import CreateJob from "./components/Job/CreateJob";
import ShowJob from "./components/Job/ShowJob";
import ListSchedule from "./components/Schedule/ListSchedule";
import CreateSchedule from "./components/Schedule/CreateSchedule";
import ListJobType from "./components/JobType/ListJobType";
import JobTypeCreate from "./components/JobType/CreateJobType";
import ListTask from "./components/Tasks/ListTask";
import MyLayout from "./components/Layout";
import ListIdea from "./components/Workspace/Idea/ListIdea";
import IdeaCreate from "./components/Workspace/Idea/CreateIdea";
import OutLine from "./components/Workspace/OutLine";
// import Draft from "./components/Workspace/Draft";
import Script from "./components/Workspace/Script";
import Film from "./components/Workspace/Film";
import Edit from "./components/Workspace/Edit";
// import Picture from "./components/Workspace/Picture";
import Thumb from "./components/Workspace/Thumb";
import ListVideo from "./components/Video/ListVideo";
import ShowVideo from "./components/Video/ShowVideo";
import ListUser from "./components/Users/ListUser";
import UserEdit from "./components/Users/UserEdit";
import Profile from "./components/Users/Profile";
import ListTeam from "./components/Teams/ListTeam";
import CreateTeam from "./components/Teams/CreateTeam";
import EditTeam from "./components/Teams/EditTeam";
import ListChannel from "./components/Channels/ListChannel";
import CreateChannel from "./components/Channels/CreateChannel";
import EditChannel from "./components/Channels/EditChannel";
import JobTypeEdit from "./components/JobType/EditJobType";
import CreateVideo from "./components/Video/CreateVideo";


function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}
 
if (window.location.pathname !== '/' && !window.location.pathname.startsWith('/#')) {
  window.location.href = "/#" + window.location.pathname;
}

const App = () => {
  const navigate = useNavigate();
  const [authUser, setAuthUser] = useState(null); 
  const [resources, setResources] = useState([
    { name: "task", list: ListTask },
    { name: "video", list: ListVideo, show: ShowVideo},
    { name: "job", show: ShowJob }
  ]);

  useEffect(() => {
    const userFromCookie = getCookie('auth');
    if(!userFromCookie){ navigate('/login');}
    if (userFromCookie) {
        setAuthUser(JSON.parse(decodeURIComponent(userFromCookie))); 
    }
  }, [navigate]);

  useEffect(() => {
    async function fetchData() {
      if(!authUser) return;
      let updatedResources = [
        { name: "task", list: ListTask },
        { name: "calendar", list: ListCalendar},
        { name: "video", list: ListVideo, show: ShowVideo},
        { name: "job", show: ShowJob },
        { name: "user",  show: Profile }
      ];      
      if(authUser.role === 1){
        const job = updatedResources.findIndex(item => item.name === "job");
        updatedResources[job] = { name: "job", list: ListJob, create: CreateJob, show: ShowJob };
        const user = updatedResources.findIndex(item => item.name === "user");
        updatedResources[user] = { name: "user", list: ListUser, edit: UserEdit, show: Profile };
        const video = updatedResources.findIndex(item => item.name === "video");
        updatedResources[video] = { name: "video", list: ListVideo, show: ShowVideo, create:CreateVideo};

        updatedResources.push(
          {name: "schedule", list: ListSchedule, create: CreateSchedule},
          { name: "jobType", list: ListJobType, create: JobTypeCreate, edit: JobTypeEdit },
          { name: "team", list: ListTeam, create: CreateTeam, edit: EditTeam},
          { name: "channel", list: ListChannel, create: CreateChannel, edit: EditChannel}
        );
      } else{
        let isManager = false;
        authUser.teams.forEach(team => {
          if(team.role === 3){isManager = true; return}
        });
        if(isManager){
          const job = updatedResources.findIndex(item => item.name === "job");
          updatedResources[job] = { name: "job", list: ListJob, create: CreateJob, show: ShowJob };

          const video = updatedResources.findIndex(item => item.name === "video");
          updatedResources[video] = { name: "video", list: ListVideo, show: ShowVideo, create:CreateVideo};
          // const user = updatedResources.findIndex(item => item.name === "user");
          // updatedResources[user] = { name: "user", list: ListUser, edit: UserEdit };
          updatedResources.push(
            {name: "schedule", list: ListSchedule, create: CreateSchedule},
            { name: "channel", list: ListChannel, create: CreateChannel, edit: EditChannel}
          );
        }
      }
      if(authUser.skills?.includes(1)){updatedResources.push({ name: "workspace/idea", list: ListIdea, create: IdeaCreate })}
      if(authUser.skills?.includes(2)){updatedResources.push({ name: "workspace/outline", list: OutLine})}
      if(authUser.skills?.includes(4)){updatedResources.push({ name: "workspace/script", list: Script})}
      if(authUser.skills?.includes(5)){updatedResources.push({ name: "workspace/film", list: Film})}
      if(authUser.skills?.includes(6)){updatedResources.push({ name: "workspace/edit", list: Edit})}
      if(authUser.skills?.includes(8)){updatedResources.push({ name: "workspace/thumbnail", list: Thumb})}
      
      setResources(updatedResources);
    };
    
    fetchData();
    
  },[authUser]);

  return (
    <UserContext.Provider value={authUser}>
      <Admin 
        dataProvider={restProvider(process.env.REACT_APP_API_URL+'api')} 
        authProvider = {authProvider}
        layout={MyLayout} 
        catchAll={NotFound}
      >
        {resources.map(resource => (
          <Resource key={resource.name} {...resource} />
        ))}
      </Admin>
    </UserContext.Provider>
  );
}

const AppWithRouter = () => {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWithRouter;