import React, {useContext, useState, useMemo } from "react";
import { List, Datagrid, TextField, ReferenceField, FunctionField, Link ,useRefresh, Filter, SelectInput } from 'react-admin';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

import { UserContext } from "../auth/UserContext";
import axios from "../axiosConfig";
import { stringify } from 'query-string';
import { StaffRowStyle } from "../utils/CustomRowStyle";
import { CustomRush } from "../utils/CustomField";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Script = (props) => {
    const refresh = useRefresh();
    const [isLoading, setIsLoading] = useState(false);
    const authUser = useContext(UserContext);
    const userTeams = authUser.teams? authUser.teams.map(team => team.teamId): [];
    const [isRedo, setIsRedo] = useState(false);
    const [reasonRedo, setReasonRedo] = useState(null); 
    const [isMsgRedo, setIsMsgRedo] = useState(false);
    const [msgRedo, setMsgRedo] = useState(null);
    const [scriptActive, setScriptActive] = useState(false);
    const [filter, setFilter] = useState({OR: [{ task:{job: {team: { managers: { has: authUser.id } }}}},{staffId:authUser.id}]});

    const [isSelectTask, setIsSelectTask] = useState(false);
    const [choiseTask, setChoiseTask] = useState(false);
    const [task, setTask] = useState([]);

    const ScriptFilter = () => {
        const status = useMemo(() => [
            { id: 'Open', name: 'New' },
            { id: 'Redo', name: 'Redo' },
            { id: 'Submited', name: 'Submited' },
            { id: 'Completed', name: 'Completed' }, 
        ], []);    
        return (
            <Filter >
                <SelectInput label="Status" source="status" choices={status} alwaysOn onChange={filterWithStatus} />
            </Filter>
        );
    };

    const filterWithStatus = () => {
        setFilter({OR: [{ task:{job: {team: { managers: { has: authUser.id } }}}},{staffId:authUser.id}]});
    }

    const handleJoinJob = async (record) => {
        setScriptActive(record);
        const query = {filter: JSON.stringify({ status: "open", type: 4, staffId:record.staffId })};
        try {
            setIsLoading(true); 
            const choise = [];
            const response = await axios.get(process.env.REACT_APP_API_URL+'api/task/joinJob?'+ stringify(query));
            response.data.map((item,index) => (
                choise.push({id:item.id,name:`${item.job.name} `})
            ));
            setChoiseTask(choise);
            setIsSelectTask(true);
        } catch (error) {
            console.log(error);
        } finally {
            await refresh(); 
            setIsLoading(false);
        }
    };

    const joinJob = async () => {
        if(!task) return;
        setIsLoading(true);
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/script/joinJob/${scriptActive.id}`,  {taskId: task});
            setIsSelectTask(false);
        }
        catch(err){
            console.log(err);
        } finally {
            setTask(false);
            setScriptActive(false);
            refresh();
            setIsLoading(false);
        }
    }

    const handleAction = async (id, status, taskId) => {
        if(!window.confirm(`Are you sure you want to ${status} this Script?`)) return;
        setIsLoading(true);    
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/script/${id}`,  {status: status});  
            if(status === 'Redo') await axios.put(`${process.env.REACT_APP_API_URL}api/task/reject/${taskId}`, { // update task
                reason:reasonRedo,  
                userReject:authUser.id
            }); 
        }
        catch(err){
            console.log(err);
        } finally {
            await refresh();
            setScriptActive(false);
            setIsLoading(false);
        } 
    };

    const MessageField = ({data}) => { 
        if(!data[0]||!data[0].dataReject) return null;
        let strMsg = data[0].dataReject.reason;
        if(data[0].dataReject.reason.length>50){strMsg = data[0].dataReject.reason.substring(0,48)+"... ";}
        return <span title="click view more..." onClick={()=>handleMsgClick(data[0].dataReject.reason)} style={{cursor: "pointer"}}>{strMsg}</span>;     
    }

    const handleMsgClick = (data) => {
        setIsMsgRedo(true);
        setMsgRedo(data);
    }

    return (
        <List {...props} filter={filter}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
            <Dialog 
                open={isRedo}
                onClose={()=>setIsRedo(false)}
                fullWidth={true}
            >
                <DialogTitle>Reason for Reject: </DialogTitle>
                <DialogContent>
                    <textarea  
                        fullWidth  
                        style={{ width: '99%', minHeight: '8em' }}
                        onChange={(e) => setReasonRedo(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{handleAction(scriptActive.id,"Redo",scriptActive.taskId);  setIsRedo(false);}} color="primary">Reject </Button>
                    <Button onClick={()=>setIsRedo(false)} color="primary">Cancel </Button>
                </DialogActions>
            </Dialog>
            <Dialog  //Dialog for msg edit
                open={isMsgRedo}
                onClose={()=>{setIsMsgRedo(false); setMsgRedo(null)}}
                fullWidth={true}
            >
                <DialogTitle>Reason for Reject: </DialogTitle>
                <DialogContent>
                    <textarea  
                        fullWidth  
                        style={{ width: '99%', minHeight: '8em' }}
                        value={msgRedo}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={isSelectTask}
                onClose={()=>setIsSelectTask(false)}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Select Task</DialogTitle>
                <DialogContent>
                    <span>Task:   </span>
                    <select onChange={(e) => setTask(parseInt(e.target.value))}>
                        <option value="0">Select Task</option> {choiseTask && choiseTask.map((item,index) => (<option value={item.id}>{item.name}</option>))} 
                    </select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>joinJob()} color="primary">Ok </Button>
                    <Button onClick={()=>setIsSelectTask(false)} color="primary">Cancel </Button>
                </DialogActions>
            </Dialog>
            <ScriptFilter/>
            <Datagrid rowStyle={StaffRowStyle(authUser, userTeams)} bulkActionButtons={false}>
                <FunctionField label="Task Title" render={(record) =>(
                    <Link target="_blank" to={`https://docs.google.com/document/d/${record.googleId}/edit`} title="click to open">
                        {record.task.job.name}
                    </Link>
                )} />
                <FunctionField label="Message" render={(record) =>(<MessageField data={record.task.statusDetail} /> )} />
                <FunctionField label="" render={record =>(
                    <a href={`/#/job/${record.task.job.id}/show`} target="_blank"  rel="noreferrer">View Project</a>
                )} />
                <ReferenceField source="staffId" reference="User">
                    <FunctionField label="User" render={(record) => `${record.firstName} ${record.lastName}`} />
                </ReferenceField>
                <TextField source="status" />
                <FunctionField label="Actions" render={(record) => {
                    const indexInTeam = userTeams.indexOf(record.task.job.team.id);
                    switch (record.status) {
                        case 'Open':
                        case 'Redo':
                            if(authUser.teams[indexInTeam] && authUser.teams[indexInTeam].role > 1 && record.staffId === authUser.id){
                                return <Button variant="outlined" color="primary" onClick={() => handleAction(record.id,"Approved", false)}>Submit</Button>
                            }
                            else{
                            return authUser.id === record.staffId?(
                                <Button variant="outlined" color="primary" onClick={() => handleAction(record.id,"Submitted", false)}>Submit</Button>
                                ):(
                                <span>Waiting for Submission</span>);
                            }
                        case 'Submitted':                            
                            if(authUser.teams[indexInTeam] && authUser.teams[indexInTeam].role > 1){
                            return (
                                <ButtonGroup>
                                    <Button variant="outlined" color="primary" onClick={() => handleAction(record.id,"Approved", false)}>Approve</Button>
                                    <Button variant="outlined" color="primary" onClick={() => {setIsRedo(true);setScriptActive(record)}}>Redo</Button>
                                </ButtonGroup>                                
                            )};
                            return <span>In Review</span>;
                        case 'Completed': {
                            if(record.staffId === authUser.id && (!record.info || !record.info.taskId)){
                                return <Button variant="outlined" color="primary" onClick={() =>handleJoinJob(record)} >Join Job</Button> 
                            }
                            else return <span>Completed</span>
                        }
                        case 'Approved':
                            return <span>Completed</span>;
                        default:
                            return <span>Unknown</span>;
                    }
                }} />
                <FunctionField label="Rush" render={(record) => <CustomRush record={record} setIsLoading={setIsLoading} userTeams={userTeams}/>} />
            </Datagrid>
        </List>
    )
};

export default Script;