import React, { useContext } from "react";
import {     
    TopToolbar,
    CreateButton,
    EditButton,
    List,
    Datagrid,
    TextField,
    BulkDeleteButton
} from 'react-admin';
import { UserContext } from "../auth/UserContext";

const CustomBulkActionButtons = ({ authUser }) => (
    <div>
        {authUser.role > 0 && (<BulkDeleteButton  />)}
    </div>
);

const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

const ListTeam = () => {
    const authUser = useContext(UserContext);
    return (
        <List 
            actions={authUser.role>0 && <ListActions/>}
            bulkActionButtons={<CustomBulkActionButtons authUser={authUser} />} 
            exporter={false} 
        >
            <Datagrid>            
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="country" />
                <EditButton/>
            </Datagrid>
        </List>
    );
};

export default ListTeam;