import React, {useEffect, useContext, useState, useMemo } from "react";
import { 
    List, 
    Datagrid, 
    TextField, 
    ReferenceField, 
    FunctionField, 
    Link,
    useRefresh, 
    Filter,
    SelectInput 
} from 'react-admin';
import { UserContext } from "../auth/UserContext";
import axios from "../axiosConfig";
import {StaffRowStyle} from "../utils/CustomRowStyle";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { CustomRush } from "../utils/CustomField";
import { stringify } from 'query-string';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const OutLine = (props) => {
    const refresh = useRefresh();
    const authUser = useContext(UserContext);
    const [userTeams, setUserTeams] = useState([]);
    const [isLoading, setIsLoading] = useState(false); 
    const [filter, setFilter] = useState({OR: [{ task:{job: {team: { managers: { has: authUser.id } }}}},{staffId:authUser.id}]});
    const [task, setTask] = useState([]);
    const [choiseTask, setChoiseTask] = useState(false);
    const [currentOutline, setCurrentOutline] = useState(false);
    const [isSelectTask, setIsSelectTask] = useState(false);

    useEffect(() => {
        const uniqueTeamIds = [...new Set(authUser.teams.map(item => item.teamId))];
        setUserTeams(uniqueTeamIds);
    }, [authUser]);
    
    const OutLineFilter = () => {
        const status = useMemo(() => [
            { id: 'Open', name: 'New' },
            { id: 'Completed', name: 'Completed' }, 
        ], []);    
        return (
            <Filter >
                <SelectInput label="Status" source="status" choices={status} alwaysOn onChange={filterWithStatus} />
            </Filter>
        );
    };

    const filterWithStatus = () => {
        setFilter({OR: [{ task:{job: {team: { managers: { has: authUser.id } }}}},{staffId:authUser.id}]});
    }

    const handleJoinJob= async (record) => {
        setCurrentOutline(record);
        const query = {filter: JSON.stringify({ status: "Open", type: 2, staffId:record.staffId })};
        try {
            setIsLoading(true); 
            const choise = [];
            const response = await axios.get(process.env.REACT_APP_API_URL+'api/task/joinJob?'+ stringify(query));
            response.data.map((item,index) => (
                choise.push({id:item.id,name:`${item.job.name} `})
            ));
            setChoiseTask(choise);
            setIsSelectTask(true);
        } catch (error) {
            console.log(error);
        } finally {
            await refresh(); 
            setIsLoading(false);
        }
    };

    const JoinJob = async () => {
        if(!task) return;
        setIsLoading(true);
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/outline/joinJob/${currentOutline.id}`,  {taskId: task});
            setIsSelectTask(false);
        }
        catch(err){
            console.log(err);
        } finally {
            setTask(false);
            setCurrentOutline(false);
            refresh();
            setIsLoading(false);
        }
    }

    const handleSubmit = async (id) => {  
        if(!window.confirm(`Are you sure you want to Submit this Outline?`)) return;
        setIsLoading(true);    
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/outline/${id}`,  {status: "Submitted"});     
        }
        catch(err){
            console.log(err);
        } finally {
            refresh();
            setIsLoading(false);
        }
    };

    return (
        <List {...props} filter={filter} filterDefaultValues={{ status: 'Open' }} >
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
            <OutLineFilter />
            <Dialog
                open={isSelectTask}
                onClose={()=>setIsSelectTask(false)}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Select Task</DialogTitle>
                <DialogContent>
                    <span>Task:   </span>
                    <select onChange={(e) => setTask(parseInt(e.target.value))}>
                        <option value="0">Select Task</option> {choiseTask && choiseTask.map((item,index) => (<option value={item.id}>{item.name}</option>))} 
                    </select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>JoinJob()} color="primary">Ok </Button>
                    <Button onClick={()=>setIsSelectTask(false)} color="primary">Cancel </Button>
                </DialogActions>
            </Dialog>
            <Datagrid rowStyle={StaffRowStyle(authUser, userTeams)} bulkActionButtons={false}>
                <FunctionField label="Title" render={(record) =>(
                    <Link target="_blank" to={`https://docs.google.com/document/d/${record.googleId}/edit`} title="click to open">
                        {record.task.job.name}
                    </Link>
                )} />
                <FunctionField label="" render={record =>(
                    <a href={`/#/job/${record.task.job.id}/show`} target="_blank" rel="noreferrer">View Project</a>
                )} />
                <ReferenceField source="staffId" reference="User">
                    <FunctionField label="User" render={(record) => `${record.firstName} ${record.lastName}`} />
                </ReferenceField>
                <TextField source="status" />
                <FunctionField label="Actions" render={(record) => {
                    switch (record.status) {
                        case 'Open':
                            return authUser.id === record.staffId?(
                                <Button variant="outlined" color="primary" onClick={() => handleSubmit(record.id)}>Submit</Button>
                                ):(
                                <span>Waiting for Submission</span>);
                        case 'Submitted': return <span>Completed</span>
                        case 'Completed': {
                            if(record.staffId === authUser.id && (!record.info || !record.info.taskId)){
                                return <Button variant="outlined" color="primary" onClick={() =>handleJoinJob(record)} >Join Job</Button> 
                            }
                            else return <span>Completed</span>
                        }
                        default:
                            return <span>Unknown</span>;
                    }
                }} />
                <FunctionField render={(record) => <CustomRush record={record}  setIsLoading={setIsLoading} userTeams={userTeams}/>} />
            </Datagrid>
        </List>
    );
};

export default OutLine;
