import React, { useState, useContext } from "react";
import { 
    Button, 
    Create, 
    required, 
    SimpleForm, 
    TextInput, 
    ReferenceInput,
    SelectInput,
    BooleanInput, 
    useRedirect ,
    useRefresh
} from "react-admin";
import useDrivePicker from "react-google-drive-picker";
import { UserContext } from "../../auth/UserContext";
import axios from "../../axiosConfig";

const IdeaImageFrame = ({ images }) =>(
    <div>
        {images.map((item, index) =><img src={"https://drive.google.com/thumbnail?id="+item.id} alt="img from GG" height="72" loading="lazy" />)}
    </div>
);

const IdeaCreate = () => {   
    const refresh = useRefresh();
    const authUser = useContext(UserContext);
    const [openPicker] = useDrivePicker();
    const redirect = useRedirect();

    const [ideaImg, setIdeaImg] = useState(null);
    const [noImage, setNoImage] = useState(false);

    const handleNoImageChange = event => {
        setNoImage(event.target.checked);
        setIdeaImg(null);
    };
    const handleOpenPicker = async () => {
        try {
            let token = await axios.get(`${process.env.REACT_APP_API_URL}api/token/googleToken?id=${authUser.id}`);
            let GGD = await axios.get(`${process.env.REACT_APP_API_URL}api/token/googleDrive?id=${authUser.id}`);
            openPicker({
                clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                developerKey:  process.env.REACT_APP_GOOGLE_DEVELOPER_KEY,
                viewId: "DOCS_IMAGES",
                token: token.data,
                showUploadView: true,
                showUploadFolders: true,
                supportDrives: true, 
                setIncludeFolders: true,
                setParentFolder: GGD.data? GGD.data : process.env.REACT_APP_GOOGLE_DRIVER_PENDING_APPROVAL, //folder Pending approval
                multiselect: true,
                cropping: true,
                customScopes:['https://www.googleapis.com/auth/drive',"https://www.googleapis.com/auth/drive.readonly",'https://www.googleapis.com/auth/drive.file'],
                // setOrigin: process.env.REACT_APP_GOOGLE_ORIGIN,
                callbackFunction: async(data) => {
                    if(data.viewToken && (data.viewToken[0] === 'upload' || data.viewToken[0] === 'docs-images')){
                        data.userId = authUser.id;
                        await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/idea/publicImage`, data.docs); //change to public
                    //     data.docs.map( async (item) => {item.embedUrl = item.url.replace('view?','preview?')})
                    }
                    
                    if (data.action === 'cancel') {
                        console.log('User clicked cancel/close button')
                    }
                    setIdeaImg(data.docs)
                }
            });
        } catch (error) {
            console.error("Failed to open picker", error);
        }
    };

    const findLinks = async(text) => {
        const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
        return text? text.match(urlRegex) : null;
    };

    const saveIdea = async (data) => {
        if(!noImage && !ideaImg){alert('Please select an image'); return;}
        try {
            data.images = ideaImg? ideaImg: [];
            data.staffId = authUser.id;
            data.links = await findLinks(data.links);
            data.status = authUser.role>0? 'Approved' :'Open';
            await axios.post(process.env.REACT_APP_API_URL+"api/workspace/idea", data );
        } catch (error) {
            console.error(error);
        } finally {
            await refresh();
            redirect('/workspace/idea');             
        }
    };

    return (    
        <Create>
            <SimpleForm onSubmit={saveIdea}>
                <TextInput source="title" validate={[required()]} fullWidth />
                <ReferenceInput source="teamId" reference="team"  filter={{members:{some:{userId:authUser.id, role:{not:-1}}}}} >
                    <SelectInput optionText="name" validate={[required()]} />
                </ReferenceInput>
                {!noImage && <Button onClick={handleOpenPicker}>Select Image</Button>}
                <BooleanInput source="noImage" label="No Image" onChange={handleNoImageChange} />
                {!noImage && <IdeaImageFrame images={ideaImg || []} />}
                <TextInput source="description" multiline label="Idea Description" fullWidth />
                <TextInput source="links" multiline label="Url" fullWidth />
            </SimpleForm>
        </Create>
    );
}

export default IdeaCreate;