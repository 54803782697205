import React, {useState, useContext, useEffect}  from 'react';
import { UserContext } from '../auth/UserContext';
import axios from '../axiosConfig';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { 
    Edit,
    SimpleForm,
    TextInput, 
    required, 
    useGetRecordId,
    FunctionField,
    ImageField,
    ImageInput,
    useRefresh
} from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Grid, Typography } from '@mui/material';

const Profile = props => {
    const recordId = useGetRecordId(); 
    const refresh = useRefresh();
    const authUser = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false); 
    const [isChangePwd, setIsChangePwd] = useState(false);

    useEffect(() => {
        if(authUser.id != recordId){window.location.href = '/';} // go to home if user is not authorized 
    }, [recordId,authUser]);

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();            
            fileReader.onload = (event) => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
            
            fileReader.readAsDataURL(file);
        });
    }

    const onSubmitChange = async(values) => {
        let base64File = false;
        if(values.avatar){base64File = await convertToBase64(values.avatar.rawFile)}
        setIsLoading(true);   
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/user/profile/${recordId}`, {
                firstName: values.firstName,
                lastName: values.lastName,
                avatar: base64File? {base64File:base64File, fileName: values.avatar.rawFile.name}: null
            });
        } catch(err){
            console.log(err);
        } finally {
            await refresh();
            setIsLoading(false);            
        }
    }

    const handleChangePassword = async(values) => {
        if(values.password !== values.confirmPassword){
            alert("Password and Confirm Password does not match");
            return;
        }
        setIsLoading(true);   
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/user/password/${recordId}`, {
                oldPass: values.oldPassword,
                newPass: values.password
            });
        } catch(err){
            console.log(err);
        } finally {
            await refresh();
            setIsLoading(false);            
        }

    }

    const handleCloseDialog = () => {setIsChangePwd(false)}

    return (
    <Edit title="Edit User" {...props} mutationMode="optimistic" component="div">
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
        <Dialog
            open={isChangePwd}
            onClose={handleCloseDialog}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">Change Password</DialogTitle>
            <DialogContent >
                <SimpleForm onSubmit={handleChangePassword} toolbar={false}>
                    <TextInput source="oldPassword" type="password" label="Current Password" fullWidth/>
                    <TextInput source="password" type="password" label="New Password" fullWidth/>
                    <TextInput source="confirmPassword" type="password" label="Confirm Password" fullWidth/>
                    <ButtonGroup variant="outline" color="primary" aria-label="contained primary button group">
                        <Button onClick={handleCloseDialog} variant="contained" color="warning">Cancel</Button>
                        <Button type="submit" variant="contained" color="primary" >Save</Button>
                    </ButtonGroup>
                </SimpleForm>
            </DialogContent>
        </Dialog>
        <SimpleForm onSubmit={onSubmitChange} toolbar={false}>
            <Typography variant="h6">User Info:</Typography>
            <Grid container spacing={2} >            
                <Grid item xs={3}>
                    <TextInput source="userName" label="User Name" disabled fullWidth/>
                    <TextInput source="email" label="email" fullWidth disabled/>                   
                </Grid>
                <Grid item xs={3}>
                    <TextInput source="firstName" validate={required()} label="First Name" fullWidth />
                    <TextInput source="lastName" validate={required()} label="Last Name" fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <FunctionField fullWidth label="User Avatar" render={(record) =>(
                        <img src={"https://drive.google.com/thumbnail?id="+record.picture}  alt="Avatar" style={{ width: '100px', borderRadius: '50%' }} />
                    )}/>
                    <ImageInput source="avatar"
                        label="New Avatar" accept="image/*" 
                        maxSize={500000} // 500KB
                    >
                        <ImageField source="src" />
                    </ImageInput>
                </Grid>
            </Grid>
            <ButtonGroup variant="outline" color="primary" aria-label="contained primary button group">
                <Button label="Change Password" onClick={() => setIsChangePwd(true)} variant="contained" color="success" >Change Password </Button>
                <Button label="Save" type="submit" variant="contained" color="primary" >Save </Button>
            </ButtonGroup>
        </SimpleForm>        
    </Edit>
    ) 
};

export default Profile;
