import React, {useState, useEffect, useContext}  from 'react';
import { UserContext } from '../auth/UserContext';
import axios from '../axiosConfig';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { 
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    required, 
    useGetRecordId,
    RadioButtonGroupInput,
    FunctionField,
    useRefresh
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import { AccordionSection  } from '@react-admin/ra-form-layout';

const UserEdit = props => {
    const recordId = useGetRecordId(); // get id from url
    const refresh = useRefresh();
    const authUser = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false); 
    const [teams, setTeams] = useState([]);    
    const [taskTypes, setTaskTypes] = useState([]);
    const [skillsByTeam, setSkillsByTeam] = useState({});

    const staffRoles = [
        { id: 0, name: 'Member' },
        { id: 1, name: 'Admin' }
    ];
    const permissionTeam = [
        { id: -1, name: 'Not in Team' },
        { id: 0, name: 'Staff' },
        { id: 1, name: 'Own Team' },
        { id: 2, name: 'Leader' },
        { id: 3, name: 'Manager' },
    ];

    useEffect(() => {
        let isManager = authUser.teams.some(team => team.role === 3);
        if(authUser.role === 0 || !isManager){window.location.href = '/';} // go to home if user is not manager 
        const groupByTeam = (data) => {
            return data.reduce((result, currentItem) => {
                const { teamId, taskTypeId } = currentItem;
                if (!result[teamId]) {
                    result[teamId] = {
                        teamId: teamId,
                        taskTypes: [],
                    };
                }
                result[teamId].taskTypes.push(taskTypeId);
                return result;
            }, {});
        }
        async function fetchData() {
            setIsLoading(true);
            try{
                // get all teams
                const getTeams = await axios.get(`${process.env.REACT_APP_API_URL}api/team/getTeamByUser/${recordId}`);
                setTeams(getTeams.data);
                //get all tasktype
                const getTaskTypes = await axios.get(`${process.env.REACT_APP_API_URL}api/taskType`);
                const taskTypeData = [];
                getTaskTypes.data.forEach(element => {
                    taskTypeData.push({id: element.id, name: element.name});
                });
                setTaskTypes(taskTypeData);
                //get user skill
                const getUserSkills  = await axios.get(`${process.env.REACT_APP_API_URL}api/user/userSkill/${recordId}`);
                const userSkillData = groupByTeam (getUserSkills.data);
                setSkillsByTeam(userSkillData);   
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }         
        }
        fetchData();
    }, [recordId,authUser]);

    const handleSkillChange = (teamId, item, isChecked) => {
        setSkillsByTeam(prevSkillsByTeam => {
            const updatedSkillsByTeam = {...prevSkillsByTeam };    
            if (!updatedSkillsByTeam[teamId]) {
                updatedSkillsByTeam[teamId] = {
                    teamId: teamId,
                    taskTypes: [item]
                };
                return updatedSkillsByTeam;
            }
            if (isChecked) {
                if (!updatedSkillsByTeam[teamId].taskTypes.includes(item)) {
                    updatedSkillsByTeam[teamId].taskTypes.push(item);
                }
            } else {
                const indexOfSkill = updatedSkillsByTeam[teamId].taskTypes.indexOf(item);
                if (indexOfSkill > -1) {updatedSkillsByTeam[teamId].taskTypes.splice(indexOfSkill, 1)} // remove skill if no choice
            }

            if (updatedSkillsByTeam[teamId].taskTypes.length === 0) {delete updatedSkillsByTeam[teamId]} //remove team if no skill
    
            return updatedSkillsByTeam;
        });
    }

    const onSubmitChange = async(values) => {
        for(const teamId in skillsByTeam){
            const indexOfTeam = teams.findIndex(team => team.id === parseInt(teamId));
            if(skillsByTeam[teamId].taskTypes.length === 0 && teams[indexOfTeam].members.length >0){alert("Please select at least one skill for each team"); return;}
        };
        setIsLoading(true);   
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/user/${recordId}`, {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                role: values.role,
                active: values.active,
                skillsByTeam : skillsByTeam,
                member : teams
            });
        } catch(err){
            console.log(err);
        } finally {
            await refresh();
            setIsLoading(false);            
        }
    }

    const handleChangeTeamPermission = async (value, teamId) =>{
        setTeams(prevTeams =>
            prevTeams.map(team =>{
                if(team.id === teamId){
                    //update Member
                    let updatedMembers;
                    if (value === -1) {
                        updatedMembers = []; // Remove members if value is null

                    } else if(team.members.length === 0){
                        updatedMembers = [{userId: recordId, role: value}];
                    } else{
                        updatedMembers = team.members.map(member => ({...member, role:value}));
                    }
                    //update user can post
                    let updatedUserPosts;
                    if(team.info.userPosts && team.info.userPosts.includes(parseInt(recordId))){
                        updatedUserPosts = team.info.userPosts.filter(item => item !== recordId);
                    } else {
                        updatedUserPosts = team.info.userPosts;
                    }
                    return {...team, members: updatedMembers, info: {...team.info, userPosts: updatedUserPosts}};
            }
            return team;
            })
        );
        setSkillsByTeam(prevSkillsByTeam => {
            const updatedSkillsByTeam = {...prevSkillsByTeam };    
            if (!updatedSkillsByTeam[teamId]) {
                if(value === 3){
                    updatedSkillsByTeam[teamId] = {
                        teamId: teamId,
                        taskTypes: [1,2,4,5,6,8]
                    };
                } else{
                    updatedSkillsByTeam[teamId] = {
                        teamId: teamId,
                        taskTypes: []
                    };
                }                
            } else {
                if(value === 3){
                    updatedSkillsByTeam[teamId].taskTypes = [1,2,4,5,6,8];
                } else {
                    updatedSkillsByTeam[teamId].taskTypes = [];
                }
            }        
            return updatedSkillsByTeam;
        });      
    }

    //render component
    const SkillList = ({teamId , isDisabled }) => {
        const teamIndex = teams.findIndex(team => team.id === teamId);
        let newTaskTypes = [...taskTypes];
        const staffRole = teams[teamIndex].members[0] && teams[teamIndex].members[0].role;
        const userLeader = teams[teamIndex].leaders ;

        //print skill by team
        if(teams[teamIndex].members.length>0){            
            if(isDisabled){
                newTaskTypes.forEach((taskType, index) => {taskType.disabled = true});
            } else if(staffRole === 0 || staffRole === 2 ){
                newTaskTypes.forEach((taskType, index) => {taskType.disabled = false});
            } else if(staffRole === 1){
                newTaskTypes.forEach((taskType, index) => {
                    taskType.disabled = true;
                    if(taskType.id===6|| taskType.id===8 ){taskType.disabled = false}
                    
                });
            } else {
                newTaskTypes.forEach((taskType, index) => {taskType.disabled = true});
            }
        }
        else newTaskTypes.forEach((taskType, index) => {taskType.disabled = true});
        // print permision for user post Video
        let isPost = false;
        if(teams[teamIndex].info.userPosts && teams[teamIndex].info.userPosts.includes(recordId)){isPost = true;}
        
        const handleCanPostChange = async (isChecked) => {
            setTeams(prevTeams =>
                prevTeams.map(team =>{
                    if(team.id === teamId){
                        let updatedUserPosts;
                        if (isChecked) {
                            if(team.info.userPosts && !team.info.userPosts.includes(recordId)){
                                updatedUserPosts = [...team.info.userPosts, recordId];
                            } else if(!team.info.userPosts){
                                updatedUserPosts = [recordId];
                            } else {
                                updatedUserPosts = team.info.userPosts;
                            }
                        } else {
                            if(team.info.userPosts && team.info.userPosts.includes(recordId)){
                                updatedUserPosts = team.info.userPosts.filter(item => item !== recordId);
                            } else {
                                updatedUserPosts = team.info.userPosts;
                            }
                        }
                        return {...team, info: {...team.info, userPosts: updatedUserPosts}};
                    }
                    return team;
                })
            );
        }

        const handleLeaderChange = async (isChecked) => {
            setTeams(prevTeams =>
                prevTeams.map(team =>{
                    if(team.id === teamId){
                        if(isChecked){
                            team.leaders.edit.push(recordId);
                            team.leaders.thumbnail.push(recordId);
                        } else {
                            team.leaders.edit = team.leaders.edit.filter(item => item !== recordId);
                            team.leaders.thumbnail = team.leaders.thumbnail.filter(item => item !== recordId);
                        }
                        return {...team};
                    }
                    return team;
                })
            );
        }

        return (
            <div style={{ maxWidth: '800px' }}>
                {staffRole === 3? 
                    <>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <input 
                            type="checkbox"
                            onChange={(e) => handleCanPostChange(e.target.checked)} 
                            checked={isPost}
                            disabled={isDisabled}
                        />
                        <label style={{ marginLeft: '5px' }}>Can Post Video</label>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <input 
                            type="checkbox"
                            onChange={(e) => handleLeaderChange(e.target.checked)} 
                            checked={(userLeader && (userLeader.edit.includes(recordId) || userLeader.thumbnail.includes(recordId) ))? true: false}
                            disabled={isDisabled}
                        />
                        <label style={{ marginLeft: '5px' }}>Includes Leader Permission</label>
                    </div></> : null
                }
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {newTaskTypes.map((item, index) => {
                    return (
                        <div key={index} style={{ width: '33%', padding: '5px' }}>
                            <input 
                                type="checkbox" 
                                value={item.id} 
                                onChange={(e) => handleSkillChange(teamId, item.id, e.target.checked)} 
                                checked={skillsByTeam[teamId] && skillsByTeam[teamId].taskTypes.includes(item.id)}
                                disabled={item.disabled}
                            />
                            <label>{item.name}</label>
                        </div>
                    )
                })}
                </div>
            </div>
        )
    }

    const renderTeams  = () => {
        const teamItems = [];
        teams.forEach((team, index) => {
            let permissionOfUser = team.members.length>0? team.members[0].role : -1;
            let indexOfTeam = authUser.teams.findIndex(authTeam => authTeam.teamId === team.id); 
            const disabled = authUser.role>0? false : indexOfTeam=== -1? true : authUser.teams[indexOfTeam].role === 3? false: true;
            teamItems.push(
                <AccordionSection key={team.id} label={team.name} fullWidth>
                    <SelectInput
                        fullWidth
                        source={`teamPermission_${team.name}`}
                        choices={permissionTeam}
                        validate={required()}
                        onChange={(e) => handleChangeTeamPermission(e.target.value, team.id)}
                        defaultValue={permissionOfUser}
                        disabled={disabled} 
                    />
                    <SkillList label="Skill" teamId = {team.id} isDisabled={disabled}/>
                </AccordionSection>
            )
        });
        return teamItems;
    }
    
    return (
    <Edit title="Edit User" {...props} mutationMode="optimistic" component="div">
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
        <SimpleForm onSubmit={onSubmitChange} toolbar={false}>
            <Typography variant="h6">User Info:</Typography>
            <Grid container spacing={2} >            
                <Grid item xs={6}>
                    <TextInput source="userName" label="User Name" disabled fullWidth/>
                    <TextInput source="email" validate={required()} label="email" fullWidth disabled={authUser.role<1? true:false}/>
                    <FunctionField fullWidth label="Discord ID" render={(record) => (                
                        <span>Discord ID: {record.socialId.DiscordId}</span>
                    )}/>
                    <SelectInput  fullWidth
                        source="active" 
                        choices={[
                            { id: true, name: 'Active' },
                            { id: false, name: 'InActive' }
                        ]}
                        disabled={authUser.role<1? true:false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput source="firstName" validate={required()} label="First Name" fullWidth  disabled={authUser.role<1? true:false}/>
                    <TextInput source="lastName" validate={required()} label="Last Name" fullWidth disabled={authUser.role<1? true:false}/>
                    <RadioButtonGroupInput source="role" choices={staffRoles}  validate={required()}  label="User Role" disabled={authUser.role>0? false: true}/>
                </Grid>
            </Grid>
            <Grid container spacing={1} >
                <Grid item xs={12}>
                    <Typography variant="h6">Teams Info:</Typography>
                    {renderTeams ()}
                </Grid>
            </Grid>
            <Button label="Save" type="submit" variant="contained" color="primary" >Save </Button>
        </SimpleForm>        
    </Edit>
    ) 
};

export default UserEdit;
