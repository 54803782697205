// DateFieldWithTimezone.js
import React from 'react';
import { useRecordContext } from 'react-admin';
import { format } from 'date-fns';

const DateFieldWithTimezone = ({ source}) => {
    const record = useRecordContext();
    if (!record || !record[source]) return null;
    const date = new Date(record[source]);
    const formattedDate = format(date, 'dd/MM/yyyy', { timeZone: 'UTC' });
    return <span>{formattedDate}</span>;

    // const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const record = useRecordContext();
    // if (!record) return null;
    // const date = record[source];
    // const formattedDate = moment(date).tz(browserTimezone).format('DD/MM/YYYY');
    // return <span>{formattedDate}</span>;
};

export default DateFieldWithTimezone;
