import React, { useState, useContext }  from "react";
import {     
    List,
    Datagrid,
    TextField,
    ReferenceField,
    FunctionField,
    Filter,
    SelectInput,
    SelectArrayInput,
    TextInput,
    useRecordContext,
    ShowButton,
    required,
    SimpleForm,
    SimpleFormIterator,
    ArrayInput,
    useRefresh,
} from 'react-admin';
import {
    Backdrop,
    CircularProgress
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { UserContext } from "../auth/UserContext";
import axios from '../axiosConfig';
import {VideoRowStyle} from "../utils/CustomRowStyle";
import StatusField from "./StatusField";
import PublishedDateField from "./PublishedDateField";
import ChannelField from "./ChannelField";
import InfoField from "./InfoField";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import useConstants from '../utils/useConstants';

const ListVideo = (props) =>{
    const authUser = useContext(UserContext);
    const refresh = useRefresh();
    const [isLoading, setIsLoading] = useState(false);
    const {
        videoStatusArray, 
        videoInfoArray,
        teamArray,
        userArray,
        channelArray
    } = useConstants();

    // Update Thumbnail
    const [isUpdateImages, setIsUpdateImages] = useState(false);
    const [videoActive, setVideoActive] = useState(null);
    const [typeImage, setTypeImage] = useState(null);

    const VideoUrl = ({ source }) => {
        const record = useRecordContext(); 
        const canEdit = record.team.managers.includes(authUser.id);

        let videoId = null;
        if(record.type){
            videoId = (record.job.jobInfo && record.job.jobInfo.video)? record.job.jobInfo.video :null;
        } else {videoId = record.extraInfo.videoId}

        return <>
            {videoId?<PlayArrowIcon  onClick={()=>window.open(`https://drive.google.com/file/d/${videoId}/view?usp=drive_link`, "_blank")} style={{ color: 'blue',cursor:'pointer'}}/>:<span>No Video</span>}
            {canEdit && <span style={{ color: 'blue',cursor:'pointer'}} onClick={() =>handleUpdateVideoClick(record.id)}> Replace </span>}
        </>     
    };
    
    const ImageRenderer = ({ source }) => {
        const record = useRecordContext(); 
        const canEdit = record.team.managers.includes(authUser.id);
        let data = null
        if(record.type){
            data = source==='thubmail'? record.job.jobInfo.thumbnails : record.job.jobInfo.idea;
        } else {
            data = source==='thubmail'?  record.extraInfo.thumbnails: record.extraInfo.ideas;
        }
        if(!data) {return <><span>No Image</span>{canEdit && <span style={{ color: 'blue',cursor:'pointer'}} onClick={() =>handleUpdateImagesClick(record, source)}> Replace </span>}</>}
        if(data.length < 1 || !data[0].id) return <><img src="/images/no-photo.jpg"  height="72" alt ="not found"/>{canEdit && <span style={{ color: 'blue',cursor:'pointer'}} onClick={() =>handleUpdateImagesClick(record, source)}> Replace </span>}</>;
        return (
            <div>
                {data.map((item, index) => <img 
                    key={index} 
                    src={"https://drive.google.com/thumbnail?id="+item.id} 
                    alt="img from GG" 
                    height="72" 
                    loading="lazy"  
                    style={{ marginRight: '10px',  cursor: 'pointer'  }}
                    onClick={()=>window.open(`https://drive.google.com/file/d/${item.id}/view`, "_blank")}
                /> )}
                {canEdit && <span style={{ color: 'blue',cursor:'pointer'}} onClick={() =>handleUpdateImagesClick(record,source)}> Replace </span>}
            </div>   
        )     
    };

    const DeleteButton = () => {
        const record = useRecordContext(); 
        if(record.type) return null;
        return (
            <IconButton 
                color="secondary"
                onClick={() => {
                    const confirm = window.confirm("Are you sure you want to delete this video?");
                    if (confirm) {
                        setIsLoading(true);
                        axios.delete(`${process.env.REACT_APP_API_URL}api/video/${record.id}`).then(()=>{
                            refresh();
                            setIsLoading(false);                            
                        });
                    }
                }}
            >
                <DeleteIcon />
            </IconButton>
        );
    }

    const VideoFilter = (props) => {
        return (
            <Filter {...props}>
                <TextInput label="Search" source="name"  alwaysOn/>
                <SelectArrayInput 
                    label="Status" 
                    source="status" 
                    choices={videoStatusArray} 
                    optionValue="id"
                    alwaysOn
                />
                <SelectArrayInput 
                    label="Info" 
                    source="info" 
                    choices={videoInfoArray} 
                    optionValue="id"
                    alwaysOn
                />   
                <SelectInput label="Team" source="job.teamId" choices={teamArray}  alwaysOn/>
                <SelectInput label="Channel" source="channelId" choices={channelArray}  alwaysOn/>
                <SelectInput 
                    label="Creator" 
                    source="staffId" 
                    choices={userArray} 
                    optionValue="id"
                    optionText={(choice) => `${choice.firstName} ${choice.lastName}`}
                    alwaysOn
                />                
            </Filter>
        )
    };

    const handleUpdateVideoClick = (id) => {
        const newId = prompt("Please enter new video id in Google Drive", "");

        if(newId){
            setIsLoading(true);
            axios.put(`${process.env.REACT_APP_API_URL}api/video/videoId/${id}`, { videoId:newId}).then(()=>{
                setIsLoading(false);
                refresh();
            });
        }

    }

    const handleUpdateImagesClick = (record, type) => {
        setTypeImage(type);
        setVideoActive(record);
        setIsUpdateImages(true);
    }

    const UpdateImages = async(values) => {
        try{
            setIsLoading(true);
            await axios.put(`${process.env.REACT_APP_API_URL}api/video/images/${videoActive.jobId}`, { images:values.images, type:typeImage});
        } catch (error) {
            console.error(error);
        } finally {
            refresh();
            setIsLoading(false);
            handleCloseUpdateImages();
        }
    }
    
    const handleCloseUpdateImages = () => {
        setIsUpdateImages(false);
        setVideoActive(null);
    }

    return (
    <List {...props}  filters={<VideoFilter />}  exporter={false} sort={{ field: 'id', order: 'DESC' }}  >  
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
        <Dialog open={isUpdateImages} onClose={handleCloseUpdateImages} scroll="paper">
            <DialogTitle id="scroll-dialog-title">Replace {typeImage}:</DialogTitle>
            <DialogContent >
                <SimpleForm onSubmit={UpdateImages}>
                    <ArrayInput label="Google ID of Images  Here - Click add to more" source="images" validate={[required()]} fullWidth>
                        <SimpleFormIterator>
                            <TextInput source="" label="Images Id on Google Drive" fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </DialogContent>
        </Dialog>
        <Datagrid rowStyle={VideoRowStyle} bulkActionButtons={false} >
            <TextField label="Title" source="name" sortable />
            <VideoUrl label="Video" source="type" sortable />
            <ImageRenderer source="thubmail" label="Thumbnail" />
            <ImageRenderer source="idea" label="Idea" />
            <ReferenceField source="teamId" reference="team" label="Team" sortable >
                <TextField source="name" />
            </ReferenceField>
            <ChannelField source="channelId" setLoading={setIsLoading} sortable/>
            <FunctionField label="Location" render={(record) =>{
                return <a rel="noreferrer" target="_blank" href={`https://drive.google.com/drive/folders/${record.directory}`}>Directory</a>}
            }/>       
            <PublishedDateField source="publishedDate" setLoading={setIsLoading} sortable/>
            <StatusField label="Video Status" source="status" setLoading={setIsLoading} allStatus={videoStatusArray} sortable/>
            <InfoField label="Video Info" source="status" setLoading={setIsLoading} allInfo={videoInfoArray} sortable/>
            <ReferenceField source="staffId" reference="user" sortable label="Creator">  
                <FunctionField label="User" render={(record) => `${record.firstName} ${record.lastName}`}  />
            </ReferenceField>
            <ShowButton />
            <DeleteButton />
        </Datagrid>
    </List>
    )
};

export default ListVideo;