import React, { useContext, useMemo } from "react";
import { 
    TopToolbar,
    CreateButton,
    FunctionField,
    InfiniteList,
    Datagrid,
    TextField,
    BulkExportButton,
    BulkDeleteButton,
    Filter,
    SelectInput,
    TextInput
} from 'react-admin';
import { UserContext } from "../auth/UserContext";

const CustomBulkActionButtons = ({ authUser }) => (
    <div>
        <BulkExportButton />
        {authUser.role > 0 && (<BulkDeleteButton  />)}
    </div>
);

const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

const UserFilter = () => {
    const status = useMemo(() => [
        { id: true, name: 'Active Acount' },
        { id: false, name: 'Banned Accout' }
    ], []);

    return (
        <Filter >
            <TextInput label="Search" source="userName" alwaysOn />
            <SelectInput label="Status" source="active" choices={status} alwaysOn />
        </Filter>
    );
};

const ListUser = () => {
    const authUser = useContext(UserContext);
    return (
        <InfiniteList 
            actions={authUser.role>0 && <ListActions/>}
            bulkActionButtons={<CustomBulkActionButtons authUser={authUser} />} 
            filters={<UserFilter />}
        >
            <Datagrid rowClick="edit">            
                <TextField source="id" />
                <TextField source="userName" sortable />
                <FunctionField 
                    label="Name"
                    render={(record) => (
                        <span>{record.firstName} {record.lastName}</span>
                    )}
                />
                <TextField source="active" sortable/>
            </Datagrid>
        </InfiniteList>
    );
};

export default ListUser;