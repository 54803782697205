import  {useState, useEffect, useContext } from "react";
import { isBefore, startOfDay } from 'date-fns';
import {
  useCreate,
  List,
  SimpleForm, 
  TextInput,
  ReferenceInput, 
  required, 
  DateInput, 
  SelectInput,
  useRefresh,
  Filter
} from 'react-admin';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import {Calendar } from "@react-admin/ra-calendar";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { UserContext } from "../auth/UserContext";
import axios from "../axiosConfig";
import useConstants from "../utils/useConstants";

const moment = require('moment-timezone');

const CalendarFilter = (props) => {
  const { teamArray } = useConstants();  
  return (
      <Filter {...props} >
          <SelectInput label="Team" source="teamId" choices={teamArray} alwaysOn />
      </Filter>
  );
};

const ListCalendar = (props) =>{
  const [create] = useCreate('job');
  const [isLoading, setIsLoading] = useState(false); 
  const refresh = useRefresh();

  const authUser = useContext(UserContext);
  const isAdmin = authUser?.role > 0;
  const [isManager, setIsManager] = useState(false);
  const [jobType, setJobType] = useState(false);
  const [startAt, setStartAt] = useState(new Date());
  const [isCreateJob, setIsCreateJob] = useState(false);  
  const [teamCreateJob, setTeamCreateJob] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [isShowJob, setIsShowJob] = useState(false);
  const [activeEvent, setActiveEvent] = useState(null);
  const [createNewJob, setCreateNewJob] = useState('primary');
  const [jobs, setJobs] = useState([]);

  const scheduleChoise = [
    {value : 0, name: "No Schedule"},
    {value : "daily", name: "Daily"},
    {value : 1, name: "Weekdays"},
    {value : 7, name: "Weekly"},
    {value : 14, name: "Biweekly"},
    {value : 28, name: "Monthly"},
  ];
  
  const isInvalidDate = (dayStart) => {
    let currentDay = moment();
    if(dayStart.day() === 0 || dayStart.day() === 6) {return true;} // is weekend
    if(dayStart.startOf('day').isSame(currentDay.startOf('day'))){
      dayStart.set({ hour: 20, minute: 0, second: 0, millisecond: 0 });
      if(currentDay.isAfter(dayStart)){ return true; }
    }
    if(dayStart.startOf('day').isBefore(currentDay.startOf('day'))){return true}
  };

  const checkManager = (teamId) => {
    let indexTeam = authUser.teams.findIndex(team => team.teamId === teamId);
    if(indexTeam === -1) {return false;}
    if(authUser.teams[indexTeam].role === 3) {setIsManager(true)}
    return authUser.teams[indexTeam].role === 3;
  }

  const isBeforeDate = (date) => {
    const currentDay = startOfDay(new Date());
    const startOfDate = startOfDay(date);
    return isBefore(startOfDate, currentDay);

  }

  const converter = (event) =>{
    const localDate = new Date(event.start);
    return {
      id: String(event.id), 
      title: event.title,
      start: localDate, 
      allDay: true, 
      borderColor:  event.bgrColor,
      backgroundColor: event.bgrColor,
      textColor: '#000',
      editable: isAdmin? true:checkManager(event.teamId)? isBeforeDate(localDate)? false:true:false,
      durationEditable: false,
      classNames : 'fc-event',
      //extendedProps: 
      jobId: event.jobId,
      manager: checkManager(event.teamId),
      // display: 'list-item',
    };
  };

  const handleCreateJob = async (values) => {
    let filmingDate= moment(values.date).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
    if(!isAdmin && !isManager) return;
    if (isInvalidDate(filmingDate) && !window.confirm("FilmingWeekend or Past Day are not allowed. Do you want to continue?")){return}
    setSelectedDate(filmingDate);
    setIsCreateJob(true)

  }

  const handleChangeTeam = async (value) => { 
    const responseJob = await axios.get(`${process.env.REACT_APP_API_URL}api/calendar/findJobs?teamId=${value}`);
    setJobs(responseJob.data);
   }

  const closeCreateJob = () => {
    setIsCreateJob(false); 
    setTeamCreateJob(0);       
    setJobs([]);
    setCreateNewJob('primary');
  }

  const handleJobDrop = async (info) =>{

    let filmingDate= moment(info.event.start).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
    if(isInvalidDate(filmingDate)){ //date not available
      alert('the day is not available'); 
      return;
    }
    const confirmRevert = window.confirm("Do you want to changes to this day?");
    if(!confirmRevert){return}   
    try{
      setIsLoading(true);
      await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/film/changeDate/${info.event._def.jobId}`, {filmingDate: filmingDate.utc().toISOString()});     
    } catch (error){console.log(error)} 
    finally {
      refresh();
      setIsLoading(false);
    }      
  }
  
  const handleChangeJobType = async (jobTypeId) => {
    setJobType(false);
    try {
      let jobType = await axios.get(`${process.env.REACT_APP_API_URL}api/jobType/${jobTypeId}`);
      let data = jobType.data.data? jobType.data.data.daysBeforeFilm:0;
      let data2 = moment(selectedDate);
      while(data > 0){
        data2.subtract(1,'days');
        if(data2.day() !== 0 || data2.day() !== 6) {data--;}
      }
      setStartAt(data2.toDate());
    } catch (error) {
      console.error(error);
    } finally {
      setJobType(true);
    }

  }

  const handleSubmitJob = async (values) => {
    let filmDateLocal= moment(values.filmingDate).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });

    if(values.type=== 'secondary'){
      const data ={
        jobId: values.jobId,
        filmingDate: filmDateLocal.utc().toISOString(),
      }
      setIsLoading(true);
      create('calendar',{data},{
        onSuccess : (response) => {
          refresh();
          closeCreateJob();
          setIsLoading(false);
        },
        onFailure : (error) => {console.error(error)}
      }); 
      return;
    }

    let startAtLocal = moment(values.startAt).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });

    const data ={
      description: values.description,
      jobTypeId : values.jobTypeId,
      teamId: values.teamId,
      staffId: values.staffId,
      startAt: startAtLocal.utc().toISOString(),
      filmingDate: filmDateLocal.utc().toISOString(),
      schedule : values.schedule === 'daily'? 1:values.schedule,
      daily : values.schedule === 'daily'
    }
    setIsLoading(true);
    create('job',{data},{
      onSuccess : (response) => {
        refresh();
        closeCreateJob();
        setIsLoading(false);
      },
      onFailure : (error) => {console.error(error)}
    });      
  }

  const handleJobClick = async (event) => {
    if(event.event.backgroundColor !== '#a9a9a9') {
      window.open(`/#/job/${event.event._def.extendedProps.jobId}/show`,"_blank");
      return;
    }
    if(!event.event._def.extendedProps.manager) {return;}
    try {
      setIsLoading(true);
      const job = await axios.get(`${process.env.REACT_APP_API_URL}api/job/${event.event._def.extendedProps.jobId}`);
      setActiveEvent(job.data);
      setIsShowJob(true);
    } catch (error) {
      console.error(error);
    } finally { setIsLoading(false);}
  }

  const closeEditJob = () => {
    setIsShowJob(false);
    setJobType(false);
    setActiveEvent(null);
  }

  const handleEditJob = async (values) => {
    let newStartAt = moment(values.startAt).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
    try{
      setIsLoading(true);
      await axios.put(`${process.env.REACT_APP_API_URL}api/job/updateJob/${activeEvent.jobId}`, {jobTypeId:values.jobTypeId,startAt:newStartAt.utc().toISOString()});
    } catch (error){console.log(error)}
    finally {
      refresh();
      setIsLoading(false);
      closeEditJob();
    }
  }

  return (    
    <List pagination={false} filters={<CalendarFilter />}  > 
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
      <Dialog
          open={isCreateJob}
          onClose={closeCreateJob}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
      >
          <DialogTitle id="scroll-dialog-title">Create Calendar</DialogTitle>
          <DialogContent >
            <SimpleForm onSubmit={handleSubmitJob}>
              <SelectInput 
                source="type" 
                choices={[{id:'primary', name:'Create New Job'},{id:'secondary', name:'Day 2 or n of Job'}]} 
                defaultValue={'primary'}
                onChange={(e) => setCreateNewJob(e.target.value)}
              />
            {createNewJob === 'primary' && <>
              <ReferenceInput source="jobTypeId" reference="jobType">     
                  <SelectInput optionText="name" validate={[required()]} onChange={(e) =>handleChangeJobType(e.target.value)} />
              </ReferenceInput>
              <ReferenceInput source="teamId" reference="team" filter={{members:{some:{userId:authUser.id, role:3}}}}>
                  <SelectInput optionText="name" validate={[required()]} onChange={(e)=>setTeamCreateJob(e.target.value)}  />
              </ReferenceInput>
              { teamCreateJob>0 && <ReferenceInput source="staffId" reference="user" filter={{teams:{some:{"teamId":teamCreateJob}}, active :true,taskTypes: { some: { taskTypeId: 1 } }}}>
                  <SelectInput optionText={(record)=>`${record.firstName} ${record.lastName}`} validate={[required()]} />
              </ReferenceInput>}
              {jobType===true && <DateInput source="startAt" validate={[required()]}  defaultValue={startAt} />}
              <DateInput source="filmingDate" disabled  defaultValue={new Date(selectedDate)} />
              <SelectInput
                  label="Schedule Job?"
                  source="schedule"
                  defaultValue = {7}
                  choices={ scheduleChoise }   
                  validate={[required()]}      
                  optionText="name"
                  optionValue="value"     
              />
              <TextInput source="description" validate={[required()]} fullWidth multiline label="Job Description"/>
            </>}
            {createNewJob === 'secondary' && <>
              <ReferenceInput source="teamId" reference="team" filter={{members:{some:{userId:authUser.id, role:3}}}}>
                  <SelectInput optionText="name" validate={[required()]} onChange={(e)=>handleChangeTeam(e.target.value)}  />
              </ReferenceInput>
              <SelectInput
                source="jobId"
                choices={jobs}
                optionText="name"
              />
              <DateInput source="filmingDate" disabled defaultValue={new Date(selectedDate)} />
            </>}
            </SimpleForm>
          </DialogContent>
      </Dialog>
      {activeEvent?
      <Dialog
          open={isShowJob}yarnm
          onClose={closeEditJob}
      >
          <DialogTitle id="scroll-dialog-title">Show Job</DialogTitle>
          <DialogContent >
            <SimpleForm onSubmit={handleEditJob} >
              <ReferenceInput source="jobTypeId" reference="jobType">     
                  <SelectInput 
                    optionText="name" 
                    validate={[required()]}  
                    defaultValue = {activeEvent.jobTypeId}
                  />
              </ReferenceInput>
              <ReferenceInput source="teamId" reference="team" filter={{id:activeEvent.teamId}}>
                  <SelectInput optionText="name" defaultValue={activeEvent.teamId} disabled />
              </ReferenceInput>
              <ReferenceInput source="staffId" reference="user" filter={{id:activeEvent.staffId}}>
                  <SelectInput optionText={(record)=>`${record.firstName} ${record.lastName}`}  defaultValue={activeEvent.staffId} disabled/>
              </ReferenceInput>
              <DateInput source="startAt" validate={[required()]}  defaultValue={new Date(activeEvent.startAt)} />
              <DateInput source="filmingDate" disabled  defaultValue={new Date(activeEvent.filmingDate)} />    
              <TextInput source="description" fullWidth defaultValue={activeEvent.description} multiline label="Job Description" disabled/>
            </SimpleForm>
          </DialogContent>
      </Dialog> : null}
      <Calendar 
        convertToEvent={converter}
        dateClick = {handleCreateJob}
        eventDrop = {handleJobDrop}
        eventClick={handleJobClick}
        // eventClick={(event) => {window.open(`/#/job/${event.event._def.extendedProps.jobId}/show`,"_blank")}}
        views={{
          month: {
            type: 'dayGridMonth',
            duration: { weeks: 5 },
            fixedWeekCount: false
          }
        }}
        // firstDay={1}
        // hiddenDays={[0,6]} // remove weekends
        aspectRatio = {2.5}  
      />
    </List>
  )
};

export default ListCalendar