import React, {useEffect, useContext, useState } from "react";
import {    
    useRefresh, 
    TopToolbar,
    CreateButton,
    useUpdate,
    List,
    Datagrid,
    TextField,
    ReferenceField,
    BulkDeleteButton,
    Filter,
    SelectInput,
    SelectArrayInput,
    TextInput,
    ShowButton,
    useRecordContext
} from 'react-admin';
import { UserContext } from "../auth/UserContext";
import { JobRowStyle } from "../utils/CustomRowStyle";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ExtraInfoField from "./ExtraInfoField";
import useConstants from '../utils/useConstants';

const CustomBulkActionButtons = ({ authUser }) => (
    <div>
        {authUser.role > 0 && (
            <BulkDeleteButton
                label="Delete Jobs"
                confirmContent="Are you sure you want to delete?"
                confirmTitle="Delete Job"   
                mutationMode="optimistic" 
            />
        )}
    </div>
);

const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

const JobFilter = (props) => {
    const {jobStatusArray, jobExtraInfoArray, teamArray, userArray} = useConstants();
    return (
        <Filter {...props} >
            <TextInput label="Search" source="name" alwaysOn />
            <SelectInput label="Team" source="teamId" choices={teamArray} alwaysOn />
            <SelectInput label="Job Status" source="status" choices={jobStatusArray} alwaysOn />
            <SelectArrayInput
                label="Extra Info"
                source="extraInfo"
                choices={jobExtraInfoArray}
                emptyText="All"
                alwaysOn
            />
            <SelectInput label="Staff" source="staffId" choices={userArray} alwaysOn />
        </Filter>
    );
};

const ListJob = () => {
    const authUser = useContext(UserContext);
    const isAdmin = authUser?.role > 0;
    const [isLoading, setIsLoading] = useState(false);
    const refresh = useRefresh();
    const [update] = useUpdate();
    const [isManager, setIsManager] = useState(false);

    useEffect(() => {
        if(!authUser) return;
        async function fetchData() {
            setIsLoading(true);
            try {
                authUser.teams.forEach(team => {
                    if(team.role === 3){setIsManager(true); return}
                  });
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, [authUser]);

    useEffect(() => {
        return () => {
            localStorage.removeItem('RaStore.job.listParams');
        };
    },[])

    const editTitle = (title, jobId) => {
        const newTitle = prompt('Enter a new title', title);
        if (newTitle === null || title === newTitle.trim()) return;   
        setIsLoading(true);
        update(
            'job', 
            { id: jobId, data: { name: newTitle.trim() } },
            {
                onSuccess: ({ data }) => {
                    refresh();
                    setIsLoading(false);
                },
                onFailure: (error) => {
                    console.log(error);
                },
            }
        );
    };

    const NameField = ({ source }) => {
        const record = useRecordContext();
        const isEditTitle = (record.status === 'Posted')? false : true
        return(
            <>
                <span onDoubleClick={() => editTitle(record.name.substring(10), record.id)}>{record.name}</span>
                {isEditTitle && <EditOutlinedIcon style={{ marginLeft: 5, cursor: 'pointer' }} onClick={() => editTitle(record.name.substring(10), record.id)} />}
            </>
        )
    }

    return (
        <List 
            actions={(isAdmin || isManager) && <ListActions/>}            
            filters={<JobFilter />}
            sort={{ field: 'id', order: 'DESC' }}          
        >
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
            <Datagrid rowStyle={JobRowStyle} bulkActionButtons={<CustomBulkActionButtons authUser={authUser}/>} >            
                <TextField source="id" />
                <NameField source="name" sortable/>
                <TextField source="status" label="Job Status" />
                <ExtraInfoField label="Extra Info"  source="extraInfo" setLoading={setIsLoading}/>
                <ReferenceField source="jobTypeId" reference="JobType" emptyText="Missing Job Type">
                    <TextField source="name" />
                </ReferenceField>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export default ListJob;