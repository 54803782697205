import React, { useContext } from "react";
import {     
    TopToolbar,
    CreateButton,
    EditButton,
    FunctionField,
    ReferenceField,
    List,
    Datagrid,
    TextField,
    BulkDeleteButton
} from 'react-admin';
import { UserContext } from "../auth/UserContext";

const CustomBulkActionButtons = ({ authUser }) => (
    <div>
        {authUser.role === 1 && (<BulkDeleteButton  />)}
    </div>
);

const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

const ListChannel = () => {
    const authUser = useContext(UserContext);
    return (
        <List 
            actions={authUser.role === 1 && <ListActions/>}            
            exporter={false} 
        >
            <Datagrid bulkActionButtons={<CustomBulkActionButtons authUser={authUser} />} >            
                <TextField source="id" />
                <FunctionField 
                    label="url" 
                    render={record => (<a rel="noreferrer" target="_blank" href={record.url}> {record.name}</a>)} />
                <ReferenceField source="teamId" reference="team">
                    <TextField source="name" />
                </ReferenceField>                
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default ListChannel;