import React, { useState, useEffect } from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    EditButton
  } from 'react-admin';
import axios from "../axiosConfig";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const ListJobType = (props) =>{
    const [taskTypes, setTaskTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchTaskTypes = async () => {
            setIsLoading(true);
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}api/taskType`);
                setTaskTypes(res.data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchTaskTypes();

    }, []);

    return (  
        <List {...props} exporter={false} >
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
            <Datagrid bulkActionButtons={false}>
                <TextField source="id"/>
                <TextField source="name"/>
                <FunctionField label="Step" render={record => {
                    let nameTasktype = "";
                    for(let i=0; i<record.step.length; i++){
                        let subTaskTypeName = "";
                        for(let j=0; j<record.step[i].length; j++){
                            let foundTaskType = taskTypes.find(element => element.id === record.step[i][j]);
                            if(foundTaskType){subTaskTypeName += `${foundTaskType.name},`;}
                        }
                        nameTasktype+= `[${subTaskTypeName.slice(0,-1)}],`;
                    }
                    return (<span>{nameTasktype.slice(0,-1)}</span>);

                }}/>
                <TextField source="data.daysBeforeFilm" label="Days befor Film"/>
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default ListJobType