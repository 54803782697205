import React, { useState, useContext, useEffect, useRef, useCallback   } from 'react';
import {useRecordContext, useRefresh } from 'react-admin';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from '../axiosConfig';
import { UserContext } from "../auth/UserContext";

const ChannelField = ({ source, setLoading  }) => {

    const record = useRecordContext();
    const [isEditing, setIsEditing] = useState(false);    
    const [selectedChannel, setSelectedChannel] = useState(record.channelId);
    const refresh = useRefresh();
    const authUser = useContext(UserContext);
    const canPost = record.team.info.userPosts && record.team.info.userPosts.includes(authUser.id);
    const [channels, setChannels] = useState([]); // Lưu trữ danh sách user

    const editRef = useRef(null);

    const handleDoubleClick = () => {
        if (canPost) { // Check if the user has permission to edit
            setIsEditing(true);
        }
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}api/video/${record.id}`, { channelId: parseInt(selectedChannel)});
            setIsEditing(false);
            refresh();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = useCallback(() => {
        setIsEditing(false);
        setSelectedChannel(record.channelId);
    },[record.channelId]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (editRef.current && !editRef.current.contains(event.target)) {
                handleCancel();
            }
        }
    
        if (isEditing) {
            document.addEventListener('dblclick', handleClickOutside);
        } else {
            document.removeEventListener('dblclick', handleClickOutside);
        }
        return () => {
            document.removeEventListener('dblclick', handleClickOutside);
        };
    }, [isEditing, handleCancel]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const allChannels  = await axios.get(`${process.env.REACT_APP_API_URL}api/channel/getChannels?teamId=${record.teamId}`);
                setChannels(allChannels.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [record.teamId, setLoading]);

    if (isEditing) {
        return (
            <div ref={editRef}>
                <select onChange={(e)=>setSelectedChannel(e.target.value)}>
                    <option value="">Select Channel</option>
                    {channels.map(item => {
                        if(item.teamId === record.teamId){
                            return <option key={item.id} value={item.id} >{item.name}</option>
                        } else {return null}
                    })}
                </select>
                <ButtonGroup>
                    <Button onClick={handleSave}><SaveIcon /></Button>
                    <Button onClick={handleCancel}><CancelIcon /></Button>
                </ButtonGroup>                
            </div>
        );
    }

    const channel = channels.find(channel => channel.id === record.channelId);
    return (
        channel?
        <div onDoubleClick={handleDoubleClick} style={{ cursor: 'pointer' }} title={canPost?'Double Click to Change':""}>{channel.name}</div>
        :
        <div onClick={handleDoubleClick} style={{ cursor: 'pointer' }}>{canPost? 'Click To Set':''}</div>
    );

};

export default ChannelField;